import request from '@/utils/request'

/**
 * 获取集中式公寓项目详情
 */
export const GetHouseOfItem = (params) => {
  return request({
    url: '/free/GetHouseOfItem',
    method: 'GET',
    params
  })
}
/**
 * 获取集中式公寓户型关联房源
 */
export const GetHouseByFlatType = (data) => {
  return request({
    url: '/free/GetHouseByFlatType',
    method: 'POST',
    data
  })
}
/**
 * 获取集中式公寓房源信息
 */
export const GetItmeHouse = (params) => {
  return request({
    url: '/free/GetItmeHouse',
    method: 'GET',
    params
  })
}
