<template>
  <div
    style="
      height: 100%;
      width: 100%;
      background-color: #ffffff;
      padding: 20px 0;
    "
  >
    <div class="login-container">
      <div class="login-form">
        <slot></slot>
      </div>
      <div class="login-image"></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'LoginCommon',
  setup() {}
})
</script>

<style scoped lang="scss">
.login-container {
  // width: 75%;
  // margin: 0 auto;
  // display: flex;
  // justify-content: space-between;
  // height: calc(100vh - 323px);
  // margin-bottom: 10px;
  width: 75%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin: 20px auto 10px;
  .login-form {
    width: 35%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .login-image {
    background: url('https://pc.nnfwzl.com/rent-house/rent-platform-web/homepage.png')
      no-repeat;
    background-size: 80%;
    background-position: 0% 50%;
    width: 60%;
  }
}
</style>
