<template>
  <div class=''>
    <login-common>
      <div class='login-wrapper'>
        <div class='login-ways'>
          <div class='ways-item check'>会员注册</div>
          <div class='ways-item check f13' @click='login'>返回登录</div>
        </div>
        <div class='login-form mt10'>
          <div class='tip'>
            <div style='padding: 5px'>温馨提醒：请确认身份证号和姓名无误</div>
          </div>
          <el-form
            :model='formModel'
            :rules='formRules'
            ref='registerForm'
            class='demo-ruleForm'
          >
            <el-form-item class='login-item' prop='realname'>
              <div class='login-box'>
                <div class='image-wrapper'>
                  <el-icon :size='25' color='#909399'>
                    <Avatar />
                  </el-icon>
                </div>
                <el-input
                  v-model='formModel.realname'
                  placeholder='请输入真实姓名'
                />
              </div>
            </el-form-item>
            <el-form-item class='login-item' prop='cardNo'>
              <div class='login-box'>
                <div class='image-wrapper'>
                  <el-icon :size='25' color='#909399'>
                    <CreditCard />
                  </el-icon>
                </div>
                <el-input
                  v-model='formModel.cardNo'
                  placeholder='请输入身份证号码'
                />
              </div>
            </el-form-item>
            <el-form-item class='login-item' prop='mobile'>
              <div class='login-box'>
                <div class='image-wrapper'>
                  <el-icon :size='25' color='#909399'>
                    <Iphone />
                  </el-icon>
                </div>
                <el-input
                  v-model='formModel.mobile'
                  placeholder='请输入手机号码'
                />
              </div>
            </el-form-item>

            <el-form-item class='login-item' prop='password'>
              <div class='login-box'>
                <div class='image-wrapper'>
                  <el-icon :size='25' color='#909399'>
                    <Lock />
                  </el-icon>
                </div>
                <el-input
                  v-model='formModel.password'
                  placeholder='请输入登录密码'
                  type='password'
                />
              </div>
            </el-form-item>
            <el-form-item class='login-item' prop='compassd'>
              <div class='login-box'>
                <div class='image-wrapper'>
                  <el-icon :size='25' color='#909399'>
                    <Lock />
                  </el-icon>
                </div>
                <el-input
                  v-model='formModel.compassd'
                  type='password'
                  show-password
                  placeholder='请再次确认登录密码'
                />
              </div>
            </el-form-item>

            <el-form-item class='login-item' prop='codeImg'>
              <div class='login-box'>
                <div class='image-wrapper'>
                  <el-icon :size='25' color='#909399'>
                    <Picture />
                  </el-icon>
                </div>
                <el-input
                  v-model='formModel.codeImg'
                  class='mr5'
                  placeholder='请输入图形码'
                />
                <el-image
                  :src='imageUrl'
                  style='display: flex; height: 38px; cursor: pointer'
                  @click='getIamge'
                />
              </div>
            </el-form-item>
            <el-form-item class='login-item' prop='code'>
              <div class='login-box'>
                <div class='image-wrapper'>
                  <el-icon :size='25' color='#909399'>
                    <Cellphone />
                  </el-icon>
                </div>
                <el-input
                  v-model='formModel.code'
                  placeholder='请输入短信验证码'
                  class='mr5'
                />
                <el-button
                  :type='formModel.btnType'
                  @click='sendCode'
                  class='w150'
                >{{ formModel.codeText }}
                </el-button>
              </div>
            </el-form-item>
            <el-form-item class='login-item' style='margin-bottom: 0'>
              <el-button type='primary' @click='register' style='width: 100%'
              >立即注册
              </el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class='login-tops'>
          <el-checkbox v-model='formModel.checked'></el-checkbox>
          <div class='ml5'>
            我已阅读并同意
            <span
              @click='centerDialogVisible = true'
              style='text-decoration: underline; color: #0059b1'
            >
              《南宁市住房租赁服务监管平台用户服务协议》
            </span>
          </div>
        </div>

      </div>
    </login-common>
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
// import axios from 'axios'
import { encryptByPassword } from '@/utils/util'
import {
  defineComponent,
  getCurrentInstance,
  reactive,
  ref,
  onMounted
} from 'vue'
import { registerApi, getSmsApi } from '@/api/user'

import LoginCommon from '@/components/NesiLoginCommon/index.vue'
import { randomImage } from '@/api/login'
import { ElMessage } from 'element-plus'

export default defineComponent({
  name: 'Register',
  components: {
    LoginCommon
  },
  setup() {
    const instance = getCurrentInstance()
    const router = useRouter()
    const registerForm = ref()
    const centerDialogVisible = ref(false)
    const formModel = reactive({
      realname: '',
      cardNo: '',
      mobile: '',
      password: '',
      compassd: '',
      codeImg: '',
      code: '',
      sendStatus: false,
      codeText: '获取验证码',
      btnType: 'primary'
    })
    const formRules = reactive({
      realname: [
        { required: true, message: '真实姓名不能为空', trigger: 'blur' }
      ],
      cardNo: [
        { required: true, message: '身份证号不能为空', trigger: 'blur' }
      ],
      mobile: [
        { required: true, message: '注册号码不能为空', trigger: 'blur' }
      ],
      password: [
        {
          required: true,
          trigger: 'blur',
          validator: (rule, value, callback) => {
            if (rule.required) {
              if (!value) return callback(new Error('请输入确认密码'))

              const passwordReg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z\W]{6,20}$/
              if (!value.match(passwordReg)) {
                return callback(new Error('密码过于简单,密码为6-20位的字母、数字'))
              }
            }
            callback()
          }
        }
      ],
      code: [
        { required: true, message: '短信验证码不能为空', trigger: 'blur' }
      ],
      codeImg: [
        { required: false, message: '图形验证码不能为空', trigger: 'blur' }
      ],
      compassd: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if (rule.required) {
              if (!value) return callback(new Error('请输入确认密码'))
              if (formModel.password !== value) {
                return callback(new Error('两次输入密码不一致'))
              }
            }
            callback()
          },
          trigger: 'blur'
        }
      ]
    })
    const login = () => {
      router.push('/login')
    }

    const imageUrl = ref('')
    const chekKeyVal = ref(0) // 图形验证码信息
    const getIamge = async () => {
      chekKeyVal.value = new Date().getTime()
      const resp = await randomImage({ key: chekKeyVal.value })
      imageUrl.value = resp
    }
    const loading = ref(false)
    const register = () => {
      formRules.codeImg[0].required = false
      instance.refs.registerForm.validate(async (valid) => {
        if (!valid) return false
        try {
          loading.value = true
          const playad = {
            ...formModel,
            password: encryptByPassword(formModel.mobile, formModel.password)
          }
          delete playad.compassd
          await registerApi(playad)
          console.log(playad)
          ElMessage.success('注册成功，请前往登录页面进行登录！')
          setTimeout(login, 1200)
        } finally {
          loading.value = false
        }
      })
    }
    const sendCode = () => {
      if (formModel.sendStatus) return false
      formRules.code[0].required = false
      formRules.codeImg[0].required = true
      formRules.password[0].required = false
      formRules.compassd[0].required = false
      instance.refs.registerForm.validate(async (valid) => {
        if (!valid) return false
        const params = {
          phone: formModel.mobile,
          captcha: formModel.codeImg,
          checkKey: chekKeyVal.value
        }
        const result = await getSmsApi(params)
        if (result.code != 200) {
          const msg = result.message.replace('验证码', '图形验证码')
          ElMessage.error(msg)
          if (msg === '图形验证码错误') {
            getIamge()
            formModel.codeImg = ''
          }
          return
        }
        ElMessage.success('短信发送成功！')
        formModel.sendStatus = true
        let time = 30
        const handler = setInterval(() => {
          if (time > 0) {
            time = time - 1
            formModel.codeText = '请等候(' + time + ')秒'
            formModel.btnType = 'info'
          } else {
            clearInterval(handler)
            formModel.codeText = '获取验证码'
            formModel.btnType = 'primary'
            formModel.sendStatus = false
          }
        }, 1000)
      })
    }
    const readProtocol = () => {
      return false
    }
    onMounted(() => {
      getIamge()
    })

    return {
      formModel,
      formRules,
      register,
      login,
      sendCode,
      readProtocol,
      centerDialogVisible,
      getIamge,
      imageUrl
    }
  }
})
</script>

<style scoped lang='scss'>
::v-deep .el-form-item__content {
  margin: 0 auto !important;
}

::v-deep .el-input__inner {
  border: 1px solid #eeeeee;
}

::v-deep .el-input__inner:focus {
  border-color: #409eff;
  outline: 0;
}

.login-wrapper {
  background-color: #fff;
  width: 360px;
  border-radius: 10px;
  box-shadow: 0 0 20px #eee;

  .login-ways {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 17px;
    height: 45px;
    line-height: 45px;
    color: #333333;
    width: 85%;
    margin: 0 auto;

    .ways-item {
      &.check {
        color: #268eff;
      }
    }
  }

  .login-form {
    .forget-pass {
      width: 85%;
      margin: 0 auto;
      text-align: right;
      color: #268eff;
      cursor: pointer;
      font-size: 14px;
      margin-top: 5px;
    }

    .demo-ruleForm {
      .login-item {
        width: 85%;
        margin: 0 auto 22px;

        .login-box {
          height: 52px;
          margin: 0 auto;
          display: flex;
          align-items: center;
          background: #f6f6f6;
          padding: 0 5px;
          border-radius: 5px;

          .image-wrapper {
            height: 40px;
            width: 28px;
            display: flex;
            align-items: center;
            padding-right: 5px;

            img {
              height: 28px;
              width: 28px;
            }
          }
        }
      }
    }
  }

  .login-tops {
    width: 85%;
    display: flex;
    justify-content: center;
    margin: 10px auto;
    font-size: 13px;
    color: #999;

    .register {
      color: #268eff;
      cursor: pointer;
      margin-left: 5px;
    }
  }

  .tip {
    width: 85%;
    margin: 10px auto;
    font-size: 13px;
    color: #f90;
    background: #fdf6ec;
  }
}

.mr5 {
  margin-right: 5px;
}

.f13 {
  font-size: 14px;
  cursor: pointer;
}

::v-deep .el-checkbox {
  align-items: flex-start;
}

.add_poptxt {
  height: 420px;

  .text-center {
    text-align: center;
    font-size: 18px;
  }

  p {
    margin-bottom: 15px;
    text-indent: 2em;
    line-height: 25px;
  }
}
</style>
