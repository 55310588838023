<template>
  <div class="wrapper">
    <div class="top">
      <!-- <div class="title">{{ house.community_name }}</div> -->
      <div class="context">
        <div class="left">
          <el-carousel :interval="1500" arrow="always" height="260px">
            <el-carousel-item v-for="item in imgages" :key="item">
              <el-image
                style="width: 100%; height: 100%"
                :src="item.src"
                fit="cover"
              >
                <template #error>
                  <div
                    style="
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      opacity: 0.8;
                      background: #ecf5ff;
                      flex-direction: column;
                      color: #5298f5;
                      font-size: 14px;
                    "
                  >
                    <el-image
                      style="width: 200px"
                      fit="cover"
                      src="https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png"
                    ></el-image>
                    暂无图片
                  </div>
                </template>
              </el-image>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="right">
          <h3 class="right-info-title">{{ house.apartmentName }}</h3>
          <div class="tips-wrapper">
            <div class="tip-item">
              <div class="label">所属城区：</div>
              <div class="value">{{ house.locationName }}</div>
            </div>
            <div class="tip-item">
              <div class="label">主力户型：</div>
              <div class="value">
                {{ house.mainRoomtype }}
              </div>
            </div>
            <div class="tip-item">
              <div class="label">项目名称：</div>
              <!-- <div class="value">起第{{ house.startFloor }}层，止第{{ house.terminationFloor }}层/总{{ house.buildingTotalFloor }}层</div> -->
              <div class="value">
                {{ house.itemName }}
              </div>
            </div>
            <div class="tip-item">
              <div class="label">开业时间：</div>
              <div class="value">
                {{ house.openDate ? house.openDate : '--' }}
              </div>
            </div>
            <div class="tip-item">
              <div class="label">项目地址：</div>
              <div class="value">{{ house.apartmentLocation }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="center solid-top">
      <nesi-section :title="`项目简介`">
        <p
          style="
            font-size: 14px;
            text-indent: 20px;
            line-height: 2;
            color: #666;
          "
        >
          {{ house.itemBrief }}
        </p>
      </nesi-section>
      <nesi-section :title="`联系人`" class="solid-top">
        <div class="meun-link-wrapper">
          <a class="meun-link-item" @click="showEnterpriseInfo">
            <img src="../../assets/apartment/detail-house.png" />
            <p :style="{ color: house.qyid ? '#409eff' : '#666' }">
              {{ house.qymc ? house.qymc : '---' }}
            </p>
          </a>
          <a class="meun-link-item" @click="showAgentPersonInfo">
            <img src="../../assets/apartment/detail-head.png" />
            <p :style="{ color: employeeIdVal ? '#409eff' : '#666' }">
              {{ house.director ? house.director : '---' }}
            </p>
          </a>
          <a class="meun-link-item">
            <img src="../../assets/apartment/detail-phone.png" />
            <p>{{ house.directorTel }}</p>
          </a>
        </div>
      </nesi-section>

      <nesi-section :title="`房源列表`" class="solid-top">
        <div class="house-card-wrapper">
          <el-card
            style="width: 100%"
            :style="{ 'margin-top': index > 0 ? '16px' : '0px' }"
            v-for="(item, index) in roomTypeList"
            :key="item.id"
          >
            <div style="display: flex">
              <el-image
                :src="
                  item.pics && item.pics.length
                    ? item.pics[0].src
                    : 'https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png'
                "
                class="house-root-image"
              ></el-image>
              <div class="house-list-wrapper">
                <div class="house-list-head">
                  <h4>{{ item.roomTypeName }}</h4>
                  <div>
                    <el-button-group size="small">
                      <el-button
                        @click="fetchRoomTypeChildRoom(item.id, 1)"
                        :type="item.showType === 1 ? 'primary' : ''"
                        >未租
                      </el-button>
                      <el-button
                        @click="fetchRoomTypeChildRoom(item.id, 2)"
                        :type="item.showType === 2 ? 'primary' : ''"
                      >
                        已租
                      </el-button>
                    </el-button-group>
                  </div>
                </div>
                <div
                  class="house-list-body"
                  v-if="item.houseList && item.houseList.length > 0"
                  v-loading="item.loading"
                >
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    :disabled="item.upStatus && item.upStatus === 3"
                    @click="showHouseInfo(item)"
                    v-for="item in item.houseList"
                    :key="item"
                  >
                    {{ item.apartmentRoomName }}
                  </el-button>
                  <el-button
                    size="mini"
                    type="primary"
                    plain
                    @click="showMoreItem(item, true)"
                    v-if="item.subHouseList && !item.showMore"
                    :key="item"
                  >
                    查看其它（{{ item.subHouseList.length }}套）
                  </el-button>
                  <template v-if="item && item.subHouseList && item.showMore">
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      :disabled="item.upStatus && item.upStatus === 3"
                      @click="showHouseInfo(item)"
                      v-for="item in item.subHouseList"
                      :key="item"
                    >
                      {{ item.apartmentRoomName }}
                    </el-button>
                    <el-button
                      size="mini"
                      type="primary"
                      plain
                      @click="showMoreItem(item, false)"
                      :key="item"
                    >
                      折起隐藏
                    </el-button>
                  </template>
                </div>
              </div>
            </div>
          </el-card>
        </div>
      </nesi-section>
    </div>
    <div class="bottom solid-top">
      <nesi-section :title="`周边环境`">
        <div class="map" id="map"></div>
        <div class="query-content">
          <el-tabs
            v-model="activeName"
            type="border-card"
            @tab-click="handleClick"
            class="tabs"
          >
            <el-tab-pane label="公交" name="traffic">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="教育" name="education">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="医疗" name="treatment">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="购物" name="shopping">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="银行" name="life">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="娱乐" name="entertainment">
              <div class="search-item" v-for="item in searchList" :key="item">
                <div class="context">{{ item.title }}</div>
                <div class="dis">{{ item.dis + 'km' }}</div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>
      </nesi-section>
    </div>
    <div class="tip-wrapper">
      <div class="title">
        <i class="el-icon-warning-outline mr-2"></i>风险提示信息：
      </div>
      <div class="content">
        <p>1. 本平台挂牌的拟租价格仅供参考，实际成交价格由租赁双方自行商定。</p>
        <p>
          2.
          本平台发布的房屋图片由经纪机构、租赁企业或出租人提供，仅供参考，承租前请务必实地查看房屋真实情况。
        </p>
        <p>
          3.
          签订租赁合同时，租赁双方当事人应当出示有效身份证明，出租人应当向承租人出示房屋所有权证明或其他核发权属证明的原件。
        </p>
        <p>
          4.
          本平台挂牌房屋成交后应按规定签订《南宁市房屋租赁合同》，办理租赁备案手续，非本市户籍的承租人同时应按规定办理居住证，所需资料及办理条件以法律法规规定及主管部门的要求为准。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NesiSection from '@/components/NesiSection/index.vue'
import { useRouter, useRoute } from 'vue-router'
import { deepCopy, numberFormat } from '@/utils/util'
import { getHouseById, newHouseList } from '@/api/home'
import { INSTALL_MAP, SITUATIONS_MAP } from '@/constant'
import {
  defineComponent,
  ref,
  reactive,
  computed,
  nextTick,
  onMounted
} from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'
import { GetHouseOfItem, GetHouseByFlatType } from '@/api/apartment'

export default defineComponent({
  components: {
    NesiSection
  },
  name: 'detail',
  // props: {
  //   title
  // },
  setup() {
    const activeName = ref('traffic')
    const route = useRoute()
    const router = useRouter()
    const isProxy = ref(false)
    const situationsMap = ref(deepCopy(SITUATIONS_MAP))
    const constant = reactive({
      installs: deepCopy(INSTALL_MAP),
      showInstall: []
    })
    const originData = ref([])
    const id = ref(route.query.id)
    const apartmentType = ref(route.query.apartmentType)
    const house = ref({})
    const type = ref()
    onMounted(() => {
      if (route.query.type) {
        type.value = route.query.type
      }
    })
    const callPhone = async (userId) => {
      if (!userId) {
        ElMessage.error('用户id不能为空，请联系管理员')
        return
      }
      const payload = { mode: 'ax', phone: '', userId: userId }
      // const res = await axios.post(URL.commonApi.bindPhone, payload)
      console.log(payload)
      // try {
      //   if (res.code === 200) {
      //     if (res.data.errorCode === '0') {
      //       ElMessageBox.alert(res.data.telX, '联系电话', {
      //         confirmButtonText: '确定'
      //       })
      //     } else {
      //       ElMessage.error('获取联系方式异常：' + res.data.errorMsg)
      //     }
      //   }
      // } catch (e) {
      //   ElMessage.error(e.info)
      // }
    }

    const userItem = ref({})
    const hasItem = ref(false)
    const tags = ref([])
    const minio = ref('')
    const types = ['', 'success', 'info', 'warning', 'danger']
    let circle = {}
    let map = {}
    let point = {}
    const searchList = ref([])
    const createMap = (latitude, longitude) => {
      const win = window
      const BMap = win.BMap
      map = new BMap.Map('map')
      const lat = latitude || 22.823889
      const lng = longitude || 108.372645
      point = new BMap.Point(lng, lat)
      map.centerAndZoom(point, 15) // 设置缩放级别
      // const myIcon = new BMap.Icon('../../assets/map-house-icon.png', new BMap.Size(32, 32))
      const marker = new BMap.Marker(point) // 创建点
      map.addOverlay(marker) // 增加点
      circle = new BMap.Circle(point, 1000, {
        fillColor: 'blue',
        strokeWeight: 1,
        fillOpacity: 0.3,
        strokeOpacity: 0.3
      })

      map.enableScrollWheelZoom(true) // 开启鼠标滚轮缩放
      const scaleCtrl = new BMap.ScaleControl() // 添加比例尺控件
      map.addControl(scaleCtrl)

      const opts = {
        width: 200, // 信息窗口宽度
        height: 100, // 信息窗口高度
        title: '故宫博物院', // 信息窗口标题
        message: '这里是故宫'
      }
      const infoWindow = new BMap.InfoWindow(
        '地址：北京市东城区王府井大街88号乐天银泰百货八层',
        opts
      ) // 创建信息窗口对象
      marker.addEventListener('click', () => {
        map.openInfoWindow(infoWindow, point) // 开启信息窗口
      })
      return map
    }
    // 最新房源
    const queryList = async () => {
      const res = await newHouseList({ type: 1, houseType: 3 })
      if (res.records.records.length > 4) {
        originData.value = res.records.records.slice(0, 4)
      } else {
        originData.value = res.records.records
      }
      minio.value = res.minioUrl
    }

    const toDetail = (item) => {
      router.push({ name: 'apartmentHouseDetail', query: { id: item.houseId } })
    }
    const store = useStore()
    const user = computed(() => {
      return store.getters.userInfo
    })
    // 获取头像和姓名
    const getHeadIcon = async (userId) => {
      userItem.value = user.value
      hasItem.value = true
    }

    // 根据球面坐标获得平面坐标。
    const getMecator = (poi) => {
      return map.getMapType().getProjection().lngLatToPoint(poi)
    }
    // 根据平面坐标获得球面坐标。
    const getPoi = (mecator) => {
      return map.getMapType().getProjection().pointToLngLat(mecator)
    }

    const queryPRJ = (name, longitude, latitude) => {
      const win = window
      // const opts = {
      //   position: new win.BMap.Point(longitude, latitude), // 指定文本标注所在的地理位置
      //   offset: new win.BMap.Size(-40, -60) // 设置文本偏移量
      // }
      // // 创建文本标注对象
      // const label = new win.BMap.Label(name, opts)
      // // 自定义文本标注样式
      // label.setStyle({
      //   color: 'white',
      //   borderRadius: '5px',
      //   borderColor: '#e4e7ed',
      //   padding: '2px 10px',
      //   fontSize: '16px',
      //   height: '30px',
      //   lineHeight: '30px',
      //   fontFamily: '微软雅黑',
      //   background: '#ff9900'
      // })
      const opts1 = {
        width: 40, // 信息窗口宽度
        height: 30, // 信息窗口高度
        title: '' // 信息窗口标题
      }
      const infoWindow = new win.BMap.InfoWindow(name, opts1) // 创建信息窗口对象
      map.openInfoWindow(infoWindow, new win.BMap.Point(longitude, latitude))
      // map.addOverlay(label)
    }

    const getSquareBounds = (centerPoi, r) => {
      const win = window
      const a = Math.sqrt(2) * r // 正方形边长
      const mPoi = getMecator(centerPoi)
      const x0 = mPoi.x
      const y0 = mPoi.y
      const x1 = x0 + a / 2
      const y1 = y0 + a / 2 // 东北点
      const x2 = x0 - a / 2
      const y2 = y0 - a / 2 // 西南点
      const ne = getPoi(new win.BMap.Pixel(x1, y1))
      const sw = getPoi(new win.BMap.Pixel(x2, y2))
      return new win.BMap.Bounds(sw, ne)
    }

    const searchByKey = (key) => {
      map.clearOverlays()
      searchList.value = []
      // 定义搜索关键词 (多关键词搜索)
      const myKeys = [key]
      // 检索结束后的回调函数
      const options = {
        pageCapacity: 9,
        renderOptions: { map: map },
        onSearchComplete: function (results) {
          const length = results[0].getNumPois()
          for (let i = 0; i < length; i++) {
            let title = ''
            let disPoint = ''
            if (
              results[0].getPoi(i) &&
              results[0].getPoi(i).title &&
              results[0].getPoi(i).point
            ) {
              title = results[0].getPoi(i).title
              disPoint = results[0].getPoi(i).point
              const dis = (map.getDistance(point, disPoint) / 1000).toFixed(2)
              const option = { title, dis }
              searchList.value.push(option)
            }
          }
        }
      }
      // renderOptions: 搜索结果, 地图于map实例显示，列表在 r-result显示
      const win = window
      const local = new win.BMap.LocalSearch(map, options)
      local.disableFirstResultSelection()
      const bounds = getSquareBounds(circle.getCenter(), circle.getRadius())
      local.searchInBounds(myKeys, bounds)
    }
    const longitude = ref(0)
    const latitude = ref(0)
    const proName = ref('')
    // 初始化地图
    const getLocation = async () => {
      map = createMap(house.value.latitude, house.value.longitude)
      searchByKey('公交')
      longitude.value = house.value.longitude
      latitude.value = house.value.latitude
      proName.value = house.value.itemName
      queryPRJ(
        house.value.itemName,
        house.value.longitude,
        house.value.latitude
      )
    }

    const proxyUser = ref([])
    const getProxyUser = async (houseId) => {
      const params = { houseId }
      console.log(params)
      // const res = await axios.get(URL.houseApi.selectHouseAgent, { params })
      // if (res.code === 200) {
      //   if (res.result) {
      //     const list = res.result.list
      //     if (list && list.length) {
      //       proxyUser.value = list.map(_item => {
      //         return {
      //           name: _item.name,
      //           pName: res.result.entName,
      //           phoneNo: _item.mobile,
      //           id: _item.employId,
      //           userId: _item.userId
      //         }
      //       })
      //     }
      //   }
      // } else {
      //   ElMessage({ type: 'error', message: '获取代理员工数据失败' })
      // }
    }
    const imgages = ref([])
    const roomTypeList = ref([]) // 户型列表
    const employeeIdVal = ref(null)
    const getDetail = async (apartmentId) => {
      const res = await GetHouseOfItem({ apartmentId })
      const { apartmentEntity, minioUrl, employeeId = null } = res
      // 反序列化图片信息
      if (apartmentEntity.houseInfo && apartmentEntity.houseInfo != '[]') {
        imgages.value = JSON.parse(apartmentEntity.houseInfo).map((item) => {
          return {
            src:
              item.src.indexOf('http') === -1 ? minioUrl + item.src : item.src
          }
        })
      } else {
        imgages.value = []
      }
      // 反序列化户型图片
      if (
        apartmentEntity.apartmentRooms &&
        apartmentEntity.apartmentRooms.length > 0
      ) {
        apartmentEntity.apartmentRooms = apartmentEntity.apartmentRooms.map(
          (item) => {
            let pics =
              item.roomTypePicture && item.roomTypePicture != '[]'
                ? JSON.parse(item.roomTypePicture)
                : []
            pics = pics.map((item) => {
              return {
                ...item,
                src:
                  item.src.indexOf('http') === -1
                    ? minioUrl + item.src
                    : item.src
              }
            })
            return {
              ...item,
              pics: pics
            }
          }
        )
      } else {
        apartmentEntity.apartmentRooms = []
      }
      roomTypeList.value = apartmentEntity.apartmentRooms
      console.log(roomTypeList.value)
      minio.value = minioUrl
      house.value = apartmentEntity
      employeeIdVal.value = employeeId
      fetchRoomTypeChildRoom()
      await nextTick()
      getLocation()
    }
    // 获取户型房源列表
    const fetchRoomTypeChildRoom = async (roomTypeId = null, type = 1) => {
      const listReq = roomTypeId
        ? roomTypeList.value.filter((item) => item.id === roomTypeId)
        : roomTypeList.value
      console.log('触发点击', listReq, roomTypeId, type)
      for (let i = 0; i < listReq.length; i++) {
        const itemRoom = listReq[i]
        itemRoom.loading = true
        const res = await GetHouseByFlatType({
          apartmentRoomTypeId: itemRoom.id,
          type
        })
        itemRoom.showType = type
        if (res && res.length > 29) {
          itemRoom.houseList = res.slice(0, 29)
        } else {
          itemRoom.houseList = res
        }

        itemRoom.showMore = false
        itemRoom.subHouseList = res && res.length > 29 ? res.slice(29) : null
        itemRoom.loading = false
      }
    }

    if (house.value.hosueTags) {
      tags.value = house.value.hosueTags.split(',').map((item, index) => {
        return {
          text: item,
          type: types[index % 5]
        }
      })
    }

    // 查看户型房源信息
    const showHouseInfo = (item) => {
      const routeUrl = router.resolve({
        name: 'apartmentHouseDetail',
        query: { id: item.houseId }
      })
      window.open(routeUrl.href, '_blank')
    }

    const handleClick = (val) => {
      searchByKey(val.props.label)
      queryPRJ(proName.value, longitude.value, latitude.value)
    }
    // 查看企业信息
    const showEnterpriseInfo = () => {
      if (apartmentType.value && apartmentType.value === '1') {
        return
      }
      router.push({
        path: `/enterpriseInfo/detail/${house.value.qymc}`
      })
    }
    // 查看企业经纪人信息
    const showAgentPersonInfo = () => {
      if (apartmentType.value && apartmentType.value === '1') {
        return
      }
      if (type.value == 6) return
      if (!employeeIdVal.value) return
      router.push({
        path: `/enterpriseInfo/agent/${employeeIdVal.value}`
      })
    }
    // 查看更多数据
    const showMoreItem = (item, flag) => {
      item.showMore = flag
    }

    getDetail(id.value)
    queryList()
    return {
      hasItem,
      userItem,
      getHeadIcon,
      callPhone,
      activeName,
      searchList,
      house,
      imgages,
      tags,
      constant,
      originData,
      minio,
      toDetail,
      handleClick,
      isProxy,
      proxyUser,
      situationsMap,
      numberFormat,
      showHouseInfo,
      showMoreItem,
      fetchRoomTypeChildRoom,
      roomTypeList,
      showEnterpriseInfo,
      showAgentPersonInfo,
      employeeIdVal
    }
  }
})
</script>

<style scoped lang="scss">
.solid-top {
  border-top: 1px dashed #c3bfbf;
  padding-top: 16px;
}

.wrapper {
  width: 100%;
  height: 100%;

  .top {
    .title {
      font-size: 20px;
      font-weight: 800;
      height: 40px;
      line-height: 40px;
    }

    .context {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .left {
        width: 51%;
        position: relative;

        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1000;
          background: rgba(0, 0, 0, 0.3);
          color: white;
          height: 40px;
          line-height: 40px;
          width: 100%;
          text-align: center;
          font-size: 14px;
        }
      }

      .right {
        flex: 1;
        height: 260px;
        padding-left: 24px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .right-info-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 36px;
          color: #000;
        }

        .fields-wrapper {
          display: flex;
          border-bottom: 1px solid #e4e7ed;
          border-top: 1px solid #e4e7ed;
          padding: 10px 0;

          .field-item {
            width: 33.3%;
            display: flex;
            align-items: center;
            flex-direction: column;
            border-left: 1px solid #e4e7ed;

            &:last-child {
              border-right: 1px solid #e4e7ed;
            }

            .value {
              font-size: 24px;
            }

            .label {
              margin-top: 5px;
              color: #909399;
            }
          }
        }

        .tips-wrapper {
          margin: 5px 0;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          .tip-item {
            display: flex;
            height: 27px;
            line-height: 27px;

            .label {
              color: #909399;
              font-size: 14px;
              width: 85px;
              text-align: left;
            }

            .value {
              font-size: 14px;
            }
          }
        }

        .tags {
          .el-tag {
            margin-right: 20px;
          }
        }
      }
    }
  }

  .center {
    margin-top: 32px;
    display: flex;
    flex-direction: column;

    .meun-link-wrapper {
      display: flex;
      width: 100%;
      justify-content: space-around;

      .meun-link-item {
        display: block;
        min-width: 130px;
        text-align: center;

        img {
          width: 94px;
          height: 91px;
          line-height: 30px;
          border-radius: 80px;
          text-align: center;
        }

        p {
          margin-top: 8px;
        }
      }

      .meun-link-item:nth-child(1) img {
      }
    }

    .house-card-wrapper {
      width: 100%;

      .house-root-image {
        height: 300px;
        width: 450px;
        padding-left: 10px;
      }

      .house-list-wrapper {
        flex: 1;
        padding: 8px;

        .house-list-head {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          h4 {
            display: block;
            flex: 1;
            font-size: 16px;
          }
        }

        .house-list-body {
          margin-top: 16px;
          display: grid;
          grid-template-columns: repeat(auto-fill, 120px);
          column-gap: 8px;
          row-gap: 8px;

          .el-button--primary.is-plain {
            margin-top: 8px;
          }

          .el-button + .el-button {
            margin-left: unset;
          }
        }
      }
    }
  }

  .bottom {
    margin-top: 32px;

    ::v-deep .section-wrapper {
      padding: 0;
      height: auto;

      .content {
        padding: 0;
        position: relative;

        .map {
          height: 500px;
          width: 100%;
        }

        .query-content {
          width: 416px;
          max-height: 460px;
          background: white;
          z-index: 10000;
          position: absolute;
          right: 20px;
          top: 20px;

          .search-item {
            height: 40px;
            border-bottom: solid 1px #e4e7ed;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            margin: 0 15px;

            &:hover {
              background: #f3f4f6;
            }

            .context {
              flex: 1;
            }

            .dis {
              width: 15%;
              margin-left: 8px;
            }
          }

          .tabs {
            // padding: 0 20px;
          }
        }
      }
    }
  }

  .tip-wrapper {
    color: #ff9900;
    background: #fdf6ec;
    padding: 10px;
    border-radius: 5px;

    .title {
      font-size: 14px;
    }

    .content {
      margin-top: 5px;
      // padding-left: 10px;
      font-size: 13px;

      p {
        height: 20px;
        line-height: 20px;
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 35px;
}

.cust-i {
  height: 40px;
  width: 50px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 15px 0;
}
</style>
