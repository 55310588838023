<template>
  <div class="section-wrapper">
    <div class="title">
      {{ setion.title }}
    </div>
    <div class="content">
      <slot></slot>
    </div>
    <div></div>
  </div>
</template>

<script>
import { defineComponent, reactive } from 'vue'
export default defineComponent({
  name: 'title-section',
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const setion = reactive({ title: props.title })
    return { setion }
  }
})
</script>

<style scoped lang="scss">
.section-wrapper {
  width: 100%;
  height: 100%;

  .title {
    height: 40px;
    line-height: 36px;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 800;

    &::before {
      content: '';
      height: 30%;
      width: 3px;
      background: #2979ff;
      display: block;
      margin-right: 3px;
      border-radius: 2px;
    }
  }

  .content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;
    justify-content: space-between;

    &:last-child {
      margin-bottom: none;
    }
  }
}
</style>
