<template>
  <div class='wrapper'>
    <div class='top'>
      <!-- <div class="title">{{ house.community_name }}</div> -->
      <div class='context'>
        <div class='left'>
          <el-carousel :interval='1500' arrow='always' height='330px'>
            <el-carousel-item v-for='item in imgages' :key='item'>
              <el-image style='width: 100%; height: 100%' :src='item.src' fit='cover'>
                <template #error>
                  <div
                    style='
                      width: 100%;
                      height: 100%;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      opacity: 0.8;
                      background: #ecf5ff;
                      flex-direction: column;
                      color: #5298f5;
                      font-size: 14px;
                    '
                  >
                    <el-image
                      style='width: 200px'
                      fit='cover'
                      src='https://pc.nnfwzl.com/rent-house/rent-platform-web/default.png'
                    ></el-image>
                    暂无图片
                  </div>
                </template>
              </el-image>
            </el-carousel-item>
          </el-carousel>
          <div class='tip'>
            温馨提示：房源图片真实性由发布方负责，平台对此不承担任何责任
          </div>
        </div>
        <div class='right'>
          <h3 class='right-info-title'>
            {{ house.apartment_room_name }}
          </h3>
          <div class='tips-wrapper'>
            <div class='tip-item'>
              <div class='label'>所属城区：</div>
              <div class='value'>{{ house.location_name }}</div>
            </div>
            <div class='tip-item'>
              <div class='label'>出租方式：</div>
              <div class='value'>
                {{ house.rent_type === 1 ? '整租' : '分租' }}
              </div>
            </div>
            <div class='tip-item'>
              <div class='label'>出租面积：</div>
              <div class='value'>{{ house.space }}㎡</div>
            </div>
            <div class='tip-item'>
              <div class='label'>开业时间：</div>
              <div class='value'>
                {{ house.updated_at ? house.updated_at.split(' ')[0] : '' }}
              </div>
            </div>

            <div class='tip-item'>
              <div class='label'>项目地址：</div>
              <div class='value'>{{ house.apartment_location }}</div>
            </div>
          </div>
          <div class='btn-action'>
            <el-button type='primary' @click='JumpToAppointment(1)'
            >预约看房
            </el-button
            >
            <el-button type='primary' @click='JumpToAppointment(2)'
            >预约签约
            </el-button
            >
          </div>
        </div>
      </div>
    </div>
    <!-- 配套设施   -->
    <div class='center'>
      <div class='left'>
        <nesi-section :title='`配套设施`'>
          <div class='install-wrapper'>
            <div
              class='install-item'
              v-for='item in constant.showInstall'
              :key='item'
            >
              <i
                class='cust-i custom-icon'
                :style='{ color: item.color }'
                :class='item.icon'
              ></i>
              <div class='label' :class='{ check: item.isCheck }'>
                {{ item.text }}
              </div>
            </div>
          </div>
        </nesi-section>
      </div>
      <nesi-section :title='`联系人`'>
        <div class='meun-link-wrapper'>
          <a class='meun-link-item' @click='showEnterpriseInfo'>
            <img src='../../assets/apartment/detail-house.png' />
            <p :style="{ color: house.qyid ? '#409eff' : '#666' }">
              {{ house.qymc ? house.qymc : '---' }}
            </p>
          </a>
          <a class='meun-link-item' @click='showAgentPersonInfo'>
            <img src='../../assets/apartment/detail-head.png' />
            <p :style="{ color: house.employee_id ? '#409eff' : '#666' }">
              {{ house.czname ? house.czname : '---' }}
            </p>
          </a>
          <a class='meun-link-item'>
            <img src='../../assets/apartment/detail-phone.png' />
            <p>{{ house.director_tel }}</p>
          </a>
        </div>
      </nesi-section>
    </div>

    <div class='tip-wrapper'>
      <div class='title'>
        <i class='el-icon-warning-outline mr-2'></i>风险提示信息：
      </div>
      <div class='content'>
        <p>1. 本平台挂牌的拟租价格仅供参考，实际成交价格由租赁双方自行商定。</p>
        <p>
          2.
          本平台发布的房屋图片由经纪机构、租赁企业或出租人提供，仅供参考，承租前请务必实地查看房屋真实情况。
        </p>
        <p>
          3.
          签订租赁合同时，租赁双方当事人应当出示有效身份证明，出租人应当向承租人出示房屋所有权证明或其他核发权属证明的原件。
        </p>
        <p>
          4.
          本平台挂牌房屋成交后应按规定签订《南宁市房屋租赁合同》，办理租赁备案手续，非本市户籍的承租人同时应按规定办理居住证，所需资料及办理条件以法律法规规定及主管部门的要求为准。
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import NesiSection from '@/components/NesiSection/index.vue'
import { useRouter, useRoute } from 'vue-router'
import { deepCopy, numberFormat } from '@/utils/util'
import { GetItmeHouse } from '@/api/apartment'
import { INSTALL_MAP, SITUATIONS_MAP } from '@/constant'
import { defineComponent, ref, reactive, computed } from 'vue'
import { ElMessage } from 'element-plus'
import { useStore } from 'vuex'

export default defineComponent({
  components: {
    NesiSection
  },
  name: 'houseDetail',
  setup() {
    const activeName = ref('traffic')
    const route = useRoute()
    const router = useRouter()
    const isProxy = ref(false)
    const situationsMap = ref(deepCopy(SITUATIONS_MAP))
    const constant = reactive({
      installs: deepCopy(INSTALL_MAP),
      showInstall: []
    })
    const originData = ref([])
    const id = ref(route.query.id)
    const house = ref({})

    const callPhone = async (userId) => {
      if (!userId) {
        ElMessage.error('用户id不能为空，请联系管理员')
        return
      }
      const payload = { mode: 'ax', phone: '', userId: userId }
      // const res = await axios.post(URL.commonApi.bindPhone, payload)
      console.log(payload)
    }

    const handlerInstalls = (installStr) => {
      if (installStr) {
        const list = installStr.split(',')
        if (list.length) {
          constant.showInstall = constant.installs.map((install) => {
            list.map((item) => {
              if (install.text === item) {
                install.isCheck = true
                install.color = '#303133'
              }
            })
            return install
          })
        }
      }
    }
    const userItem = ref({})
    const hasItem = ref(false)
    const tags = ref([])
    const minio = ref('')
    const types = ['', 'success', 'info', 'warning', 'danger']

    const searchList = ref([])

    const toDetail = (item) => {
      router.push({ name: 'houseDetail', query: { id: item.houseId } })
    }
    const store = useStore()
    const user = computed(() => {
      return store.getters.userInfo
    })
    // 获取头像和姓名
    const getHeadIcon = async (userId) => {
      userItem.value = user.value
      hasItem.value = true
    }

    const proxyUser = ref([])
    const getProxyUser = async (houseId) => {
      const params = { houseId }
      console.log(params)
    }
    const imgages = ref([])
    const getDetail = async (houseId) => {
      const res = await GetItmeHouse({ houseId })
      const item = res
      house.value = res
      minio.value = res.minioUrl
      if (item.type === 17) {
        isProxy.value = true
        getProxyUser(item.id)
      }
      handlerInstalls(item.collocation_name)
      getHeadIcon(item.userId)
      if (item.houseTags) {
        tags.value = item.houseTags.split(',').map((item, index) => {
          return {
            text: item,
            type: types[index % 5]
          }
        })
      }
      imgages.value = JSON.parse(item.house_other).map((img) => {
        return { src: minio.value + (img.src || img.smjsj) }
      })
    }
    if (house.value.hosueTags) {
      tags.value = house.value.hosueTags.split(',').map((item, index) => {
        return {
          text: item,
          type: types[index % 5]
        }
      })
    }

    getDetail(id.value)

    // 查看企业信息
    const showEnterpriseInfo = () => {
      router.push({
        path: `/enterpriseInfo/detail/${house.value.qymc}`
      })
    }
    // 查看企业经纪人信息
    const showAgentPersonInfo = () => {
      router.push({
        path: `/enterpriseInfo/agent/${house.value.employee_id}`
      })
    }
    // 预约看房跳转
    const JumpToAppointment = (appointmentType) => {
      const houseInfo = house.value
      const routerUrl = router.resolve({
        path: '/user/appointment',
        query: {
          fwid: houseInfo.id,
          appointmentType: appointmentType,
          czqsrq: houseInfo.czqsrq,
          czzzrq: houseInfo.czzzrq
        }
      })
      window.open(routerUrl.href, '_blank')
    }
    return {
      hasItem,
      userItem,
      getHeadIcon,
      callPhone,
      activeName,
      searchList,
      house,
      imgages,
      tags,
      constant,
      originData,
      minio,
      toDetail,
      isProxy,
      proxyUser,
      situationsMap,
      numberFormat,
      showEnterpriseInfo,
      showAgentPersonInfo,
      JumpToAppointment
    }
  }
})
</script>

<style scoped lang='scss'>
.wrapper {
  width: 100%;
  height: 100%;

  .top {
    .context {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;

      .left {
        width: 51%;
        position: relative;

        .tip {
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: 1000;
          background: rgba(0, 0, 0, 0.3);
          color: white;
          height: 40px;
          line-height: 40px;
          width: 100%;
          text-align: center;
          font-size: 14px;
        }
      }

      .right {
        flex: 1;
        height: 330px;
        padding-left: 15px;
        display: flex;
        flex-direction: column;

        .right-info-title {
          font-weight: 600;
          font-size: 20px;
          line-height: 36px;
          color: #000;
        }

        .tips-wrapper {
          margin: 5px 0;
          display: flex;
          align-items: flex-start;
          flex-direction: column;

          .tip-item {
            display: flex;
            height: 27px;
            line-height: 27px;

            .label {
              color: #909399;
              font-size: 14px;
              width: 78px;
              text-align: left;
            }

            .value {
              font-size: 14px;
            }
          }
        }

        .btn-action {
          display: flex;
          justify-content: center;
          margin-top: 24px;
        }
      }
    }
  }

  .center {
    margin-top: 32px;

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;

      ::v-deep .section-wrapper {
        padding: 0;
        height: auto;

        .content {
          margin-bottom: 0;
        }
      }

      .install-wrapper {
        display: flex;
        flex-wrap: wrap;

        .install-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 80px;
          margin-bottom: 20px;

          .icon {
            font-size: 50px;
          }

          .label {
            color: #909399;
            text-decoration: line-through;

            &.check {
              color: #303133;
              text-decoration: none;
            }
          }
        }
      }

      .link-wrapper {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }
    }

    .meun-link-wrapper {
      margin-top: 32px;
      display: flex;
      width: 100%;
      padding: 16px 0;
      justify-content: space-around;

      .meun-link-item {
        display: block;
        min-width: 130px;
        text-align: center;

        img {
          width: 94px;
          height: 91px;
          line-height: 30px;
          border-radius: 80px;
          text-align: center;
        }

        p {
          margin-top: 8px;
        }
      }

      .meun-link-item:nth-child(1) img {
      }
    }
  }

  .bottom {
    ::v-deep .section-wrapper {
      padding: 0;
      height: auto;

      .content {
        padding: 0;
        position: relative;

        .map {
          height: 500px;
          width: 100%;
        }

        .query-content {
          width: 416px;
          max-height: 460px;
          background: white;
          z-index: 10000;
          position: absolute;
          right: 20px;
          top: 20px;

          .search-item {
            height: 40px;
            border-bottom: solid 1px #e4e7ed;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            margin: 0 15px;

            &:hover {
              background: #f3f4f6;
            }

            .context {
              flex: 1;
            }

            .dis {
              width: 15%;
              margin-left: 8px;
            }
          }

          .tabs {
            // padding: 0 20px;
          }
        }
      }
    }
  }

  .tip-wrapper {
    color: #ff9900;
    background: #fdf6ec;
    padding: 10px;
    border-radius: 5px;

    .title {
      font-size: 14px;
    }

    .content {
      margin-top: 5px;
      // padding-left: 10px;
      font-size: 13px;

      p {
        height: 20px;
        line-height: 20px;
      }
    }
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 400px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 35px;
}

.cust-i {
  height: 40px;
  width: 50px;
  font-size: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

::v-deep .el-tabs--border-card > .el-tabs__content {
  padding: 15px 0;
}
</style>
